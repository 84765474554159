.select_support_devices_wrap button:hover {
    background-color: #b4b9c3;
}
.select_support_devices_wrap button.first {
    border-left: 1px solid #dde0e5;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.select_support_devices_wrap button.last {
    border-right: 1px solid #dde0e5;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.select_support_devices_wrap button {
    min-width: 100px;
    height: 28px;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    color: #848484;
    border-top: 1px solid #dde0e5;
    border-bottom: 1px solid #dde0e5;
    font-size: 11px;
}

.select_support_devices_wrap button.disable {
    border: 1px solid #c3c4c6;
    background: #c3c4c6;
}

.select_support_devices_wrap button.disable:hover {
    border: 1px solid #c3c4c6;
    background: #c3c4c6;
}

.device_type_btn button[disabled=disabled] {
    background-color: #c3c4c6;
}
.device_type_btn button:hover {
    background-color: #b4b9c3;
}


.select_support_devices_wrap button.on.i_player {
    border: 1px solid #86C16A;
    background: #86C16A;
    color: #fff;
}


.select_support_devices_wrap button.on.s_player {border:1px solid #638EC9;background:#638EC9;color:#fff;}
.select_support_devices_wrap button.on.s_player3 {border:1px solid #9372C5;background:#9372C5;color:#fff;}
.select_support_devices_wrap button.on.s_player2 {border:1px solid #F0A052;background:#F0A052;color:#fff;}
/*.device_type_btn #supportedSplayer.on, .device_type_btn button#supportedSplayer:active {border:1px solid #3AA3AF;background:#3AA3AF;color:#fff;}*/
.select_support_devices_wrap button.on.a_player {border:1px solid #D37171;background:#D37171;color:#fff;}
.select_support_devices_wrap button.on.s_player4 {border:1px solid #31A2FF;background:#31A2FF;color:#fff;}
.select_support_devices_wrap button.on.s_player5 {border:1px solid #c73eb9;background:#c73eb9;color:#fff;}
.select_support_devices_wrap button.on.s_player6 {border:1px solid #15DBC4;background:#15DBC4;color:#fff;}
.select_support_devices_wrap button.on.s_player7 {border:1px solid #005BCB;background:#005BCB;color:#fff;}
.select_support_devices_wrap button.on.s_player9 {border:1px solid #F88638;background:#F88638;color:#fff;}
.select_support_devices_wrap button.on.lite {border:1px solid #F3C862;background:#F3C862;color:#fff;}
.select_support_devices_wrap button.on.w_player {border:1px solid #00ee00;background:#00ff00;color:#fff;}
